import { BigNumber } from '@ethersproject/bignumber';
import { formatBytes32String } from '@ethersproject/strings';
import { Claim } from '@/classes/claimExtensionContract';

// Try catch because localStorage can throw an error if the user has disabled
export function trySetLocalStorage (key: string, value: string) {
  try {
    localStorage.setItem(key, value);
  } catch (e) {
    // ignore
  }
}

export function tryGetLocalStorage (key: string) {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    // ignore
  }
  return '';
}

export function extractProxyData (proxy: any[][]): string[][] {
  const outerArray: any[] = Array.from(proxy);
  return outerArray.map((innerArray: any[]) => Array.from(innerArray) as string[]);
}

export function isEmptyMerkleRoot (merkleTree: string): boolean {
  return merkleTree === formatBytes32String('');
}

export function isExclusiveClaim (claim: Claim): boolean {
  return !isEmptyMerkleRoot(claim.merkleRoot);
}

export function isUnlimitedSupply (claim: Claim): boolean {
  return claim.totalMax === 0;
}

export function abbreviate (str: string, keepLeft = 4, keepRight = 4): string {
  if (!str) return str;
  return (
    str.substring(0, keepLeft) + '...' + str.substring(str.length - keepRight)
  );
}

export function abbreviateAddress (address: string): string {
  return abbreviate(address, 6, 4);
}

export function pluralize (count: number, singular: string, plural?: string): string {
  if (count === 1) {
    return singular;
  } else {
    return plural || singular + 's';
  }
}

export function sleep (ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function isJSON (str: any): boolean {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export const getEtherscanBase = (network: number) => {
  if (network === 10) {
    return 'https://optimistic.etherscan.io';
  } else if (network === 5) {
    return 'https://goerli.etherscan.io';
  } else {
    return 'https://etherscan.io';
  }
};

export interface CrossmintFiatCostBreakdown {
  price: BigNumber;
  gasFees: BigNumber;
  totalPrice: BigNumber;
}

export const transformError = (err: any) => {
  let msg;
  if (err.code === 4001 || err.code === 'ACTION_REJECTED') {
    // don't show error when user rejects
    return null;
  } else if (err.code === 'INSUFFICIENT_FUNDS') {
    msg = 'Not enough funds in wallet';
  } else if (
    err.code === 'TRANSACTION_REPLACED' &&
      !err.cancelled &&
      err.replacement
  ) {
    // Check if the trasaction was sped up
    msg = `Transaction was replaced, please check https://etherscan.io/tx/${err.replacement.hash} for the status of your transaction`;
  } else if (err.code === 'TRANSACTION_REPLACED' && err.cancelled) {
    msg = 'Transaction was cancelled';
  } else if (err.code === 'CALL_EXCEPTION') {
    msg = 'Transaction Failed';
  } else if (err.code === 'UNPREDICTABLE_GAS_LIMIT' && isJSON(err.error?.body)) {
    // providers return errors where error.message is not end-user readable and
    // containing both json and plain text. Further parsing of the error.body is required
    // to get a human readable message.
    msg = JSON.parse(err.error.body).error?.message?.replace('execution reverted: ', '') || 'The simulation for your transaction predicted a failure. Refresh the page and try again.';
  } else if (err.message && err.message.includes('Ledger device')) {
    msg = 'Error with your ledger device, please try again';
  } else if (
    err.message &&
      err.message.includes('Maximum tokens already minted')
  ) {
    msg = 'You already minted the maximum allowable quantity. Try secondary!';
  } else if (err.error && err.error.message) {
    msg = err.error.message;
  } else if (
    err.error &&
      typeof err.error === 'string' &&
      err.error.includes('Address is not part of audience')
  ) {
    msg = 'You are not on the allow list for this claim';
  } else if (err.error) {
    msg = err.error;
  } else if (err.message) {
    msg = err.message;
  } else if (typeof err === 'string') {
    msg = err;
  } else {
    msg = 'Something went wrong.';
  }

  return {
    error: msg
  };
};
