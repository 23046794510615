import { BigNumber } from '@ethersproject/bignumber';
import {
  claim721Abi,
  claim721PayableAbi,
  claim1155Abi,
  claim1155PayableAbi
} from '@/abis';
import claimABI721Airdrop from '@/abis/claim-abi-721-airdrop';
import claimABI721Fee from '@/abis/claim-abi-721-fee';
import claimABI721Optimization from '@/abis/claim-abi-721-optimization';
import claimABI1155Airdrop from '@/abis/claim-abi-1155-airdrop';
import claimABI1155Fee from '@/abis/claim-abi-1155-fee';
import claimABI1155Optimization from '@/abis/claim-abi-1155-optimization';

// events
export const AUTHENTICATED = 'm-authenticated';
export const UNAUTHENTICATED = 'm-unauthenticated';
export const MANIFOLD_LOAD_REQUEST = 'm-refresh-widgets';

export const MAX_MINT_PAYABLE = 10;
export const MAX_MINT_FREE = 10;

export const FEE_PER_MINT = BigNumber.from(500000000000000); // 0.0005 ETH * 1e18;
export const FEE_PER_MERKLE_MINT = BigNumber.from(690000000000000); // 0.00069 ETH * 1e18;

export const MANIFOLD_FEE_EXPLANATION = 'Manifold charges a minimal fee for items collected through our apps while creators retain 100% of the revenue. This fee supports ongoing maintenance and future development.';

export const PROCESSING_FEES_EXPLANATION = 'Processing fees include Ethereum network expenses and card payment fees, helping to maintain a secure and efficient user experience. This includes vital components such as fraud protection, transaction monitoring, currency exchange risk management, and responsive customer support.';

export const ETHEREUM_NETWORK_COLORS: Record<number, string> = {
  1: '#59B3AE',
  3: '#EC5A8C',
  4: '#EFC45B',
  5: '#4D99EB',
  10: '#AA4A44',
  42: '#886BF6',
  137: '#7B4ADD',
  6284: '#4D99EB'
};

export const ETHEREUM_NETWORK_NAMES: Record<number, string> = {
  1: 'MAINNET',
  3: 'ROPSTEN',
  4: 'RINKEBY',
  5: 'GOERLI',
  10: 'OPTIMISM',
  42: 'KOVAN',
  137: 'MATIC',
  6284: 'GOERLI'
};

export const WALLET_LOCAL_STORAGE_KEY = 'connectedAddress';
export const JWT_LOCAL_STORAGE_KEY = 'manifoldOAuth';
export const PREVIEW_PATH = '/c/manifold-campaign-preview';

// Heads up these must all be lowercase
export const CLAIM_EXTENSION_721_V1_MAINNET = '0xb08aa31cc2b8c0582be42d38bb643292e0a4b9eb';
export const CLAIM_EXTENSION_1155_V1_MAINNET = '0xca71c5270eff44eb6d813a92c0ba12577bddf208';

export const CLAIM_EXTENSION_721_V2_GOERLI = '0xff5a6f7f36764aad301b7c9e85a5277614df5e26';
export const CLAIM_EXTENSION_721_V2_MAINNET = '0x5ebfd58c5ead1025755f7490e510ccf2c0b4a444';
export const CLAIM_EXTENSION_1155_V2_GOERLI = '0xbea4a16cc7e5efbf832a6082fd4e44693264b63f';
export const CLAIM_EXTENSION_1155_V2_MAINNET = '0x4e32004d8b81847a670b4a1778ace4dcf2bba01e';

export const CLAIM_EXTENSION_721_V3_GOERLI = '0xc3ba6907a64d63a28e5be8bff9c6c1ead7ce7ea7';
export const CLAIM_EXTENSION_721_V3_MAINNET = '0xa46f952645d4deec07a7cd98d1ec9ec888d4b61e';
export const CLAIM_EXTENSION_1155_V3_GOERLI = '0xa402f5e27b5da8d6593f6bede97be5820529d64a';
export const CLAIM_EXTENSION_1155_V3_MAINNET = '0x44e94034afce2dd3cd5eb62528f239686fc8f162';

export const CLAIM_EXTENSION_721_V4_GOERLI = '0x6910d1fb4bce932689743457837c085963fe7e2b';
export const CLAIM_EXTENSION_721_V4_MAINNET = '0x7581871e1c11f85ec7f02382632b8574fad11b22';
export const CLAIM_EXTENSION_1155_V4_GOERLI = '0x11f412403fb8f5cc7d1947763a2d86543103a601';
export const CLAIM_EXTENSION_1155_V4_MAINNET = '0xe7d3982e214f9dfd53d23a7f72851a7044072250';

export const CLAIM_EXTENSION_721_V5_GOERLI = '0x5294d02a23b193a7dccf8435859e7749bc9456aa';
export const CLAIM_EXTENSION_721_V5_MAINNET = '0x3b8c2feb0f4953870f825df64322ec967aa26b8c';
export const CLAIM_EXTENSION_1155_V5_GOERLI = '0x73ca7420625d312d1792cea60ced7b35d009322c';
export const CLAIM_EXTENSION_1155_V5_MAINNET = '0xdb8d79c775452a3929b86ac5deab3e9d38e1c006';

// Claim contracts deployed via create2 have the same address on every network
export const CLAIM_EXTENSION_721_V6 = '0x1eb73fee2090fb1c20105d5ba887e3c3ba14a17e';
export const CLAIM_EXTENSION_1155_V6 = '0x04ba6cf3c5aa6d4946f5b7f7adf111012a9fac65';

export const EXTENSION_TRAITS: { [_: string]: string[] } = {
  [CLAIM_EXTENSION_721_V1_MAINNET]: [],
  [CLAIM_EXTENSION_1155_V1_MAINNET]: [],

  [CLAIM_EXTENSION_721_V2_GOERLI]: [],
  [CLAIM_EXTENSION_1155_V2_GOERLI]: [],
  [CLAIM_EXTENSION_721_V2_MAINNET]: [],
  [CLAIM_EXTENSION_1155_V2_MAINNET]: [],

  [CLAIM_EXTENSION_721_V3_GOERLI]: ['delegateMint'],
  [CLAIM_EXTENSION_1155_V3_GOERLI]: ['delegateMint'],
  [CLAIM_EXTENSION_721_V3_MAINNET]: ['delegateMint'],
  [CLAIM_EXTENSION_1155_V3_MAINNET]: ['delegateMint'],

  [CLAIM_EXTENSION_721_V4_GOERLI]: ['delegateMint', 'fee'],
  [CLAIM_EXTENSION_1155_V4_GOERLI]: ['delegateMint', 'fee'],
  [CLAIM_EXTENSION_721_V4_MAINNET]: ['delegateMint', 'fee'],
  [CLAIM_EXTENSION_1155_V4_MAINNET]: ['delegateMint', 'fee'],

  [CLAIM_EXTENSION_721_V5_GOERLI]: ['delegateMint', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_1155_V5_GOERLI]: ['delegateMint', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_721_V5_MAINNET]: ['delegateMint', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_1155_V5_MAINNET]: ['delegateMint', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_721_V6]: ['delegateMint', 'fee', 'contractVersion'],
  [CLAIM_EXTENSION_1155_V6]: ['delegateMint', 'fee', 'contractVersion'],
  '0x28a72d7e0f1fab1d78f3c4cdd7880c3b01b8a519': ['delegateMint', 'fee', 'contractVersion']
};

export const EXTENSION_ABIS: { [_: string]: any[] } = {
  [CLAIM_EXTENSION_721_V1_MAINNET]: claim721Abi,
  [CLAIM_EXTENSION_1155_V1_MAINNET]: claim1155Abi,

  [CLAIM_EXTENSION_721_V2_GOERLI]: claim721PayableAbi,
  [CLAIM_EXTENSION_721_V2_MAINNET]: claim721PayableAbi,
  [CLAIM_EXTENSION_1155_V2_GOERLI]: claim1155PayableAbi,
  [CLAIM_EXTENSION_1155_V2_MAINNET]: claim1155PayableAbi,

  [CLAIM_EXTENSION_721_V3_GOERLI]: claimABI721Airdrop,
  [CLAIM_EXTENSION_721_V3_MAINNET]: claimABI721Airdrop,
  [CLAIM_EXTENSION_1155_V3_GOERLI]: claimABI1155Airdrop,
  [CLAIM_EXTENSION_1155_V3_MAINNET]: claimABI1155Airdrop,

  [CLAIM_EXTENSION_721_V4_GOERLI]: claimABI721Fee,
  [CLAIM_EXTENSION_721_V4_MAINNET]: claimABI721Fee,
  [CLAIM_EXTENSION_1155_V4_GOERLI]: claimABI1155Fee,
  [CLAIM_EXTENSION_1155_V4_MAINNET]: claimABI1155Fee,

  [CLAIM_EXTENSION_721_V5_GOERLI]: claimABI721Optimization,
  [CLAIM_EXTENSION_721_V5_MAINNET]: claimABI721Optimization,
  [CLAIM_EXTENSION_1155_V5_GOERLI]: claimABI1155Optimization,
  [CLAIM_EXTENSION_1155_V5_MAINNET]: claimABI1155Optimization,
  [CLAIM_EXTENSION_721_V6]: claimABI721Optimization,
  [CLAIM_EXTENSION_1155_V6]: claimABI1155Optimization,

  '0x28a72d7e0f1fab1d78f3c4cdd7880c3b01b8a519': claimABI1155Optimization
};

export const NULL_ADDRESS = '0x0000000000000000000000000000000000000000';

export const DELEGATION_REGISTRY_ADDRESS = '0x00000000000076a84fef008cdabe6409d2fe638b';

export const CROSSMINT_EMAIL_LOCAL_STORAGE_KEY = 'crossmint-email';
export const CROSSMINT_MINTFOR_LOCAL_STORAGE_KEY = 'crossmint-wallet';
